export default {
  namespaced: true,

  state: {
    message: '',
    color: '',
    timeout: '',
    showCloseButton: null
  },

  mutations: {
    'SET': (state, { message, color = 'info', timeout = 2000, showCloseButton = true }) => {
      state.message = message;
      state.color = color;
      state.timeout = timeout;
      state.showCloseButton = showCloseButton;
    },

    'RESET': (state) => {
      state.message = '';
      state.color = '';
      state.timeout = '';
      state.showCloseButton = null;
    }
  }
}