import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import Lime from './lime';
import Snack from './snack';
import Auth from './auth';

Vue.use(Vuex);

/**
 * Check registred module
 * @param {Array} aPath - path to module - ex: ['my', 'nested', 'module']
 * @return {Boolean}
 */
Vuex.Store.prototype.hasModule = function (aPath) {
  let m = this._modules.root
  return aPath.every((p) => {
    m = m._children[p]
    return m
  })
}

// make ziggy route helper available in vuex modules.
Vuex.Store.prototype.route = window.route;

const store = new Store({
  strict: true,

  modules: {
    lime: Lime,
    snack: Snack,
    auth: Auth
  }
});

export default store;