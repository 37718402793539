<template>
  <v-snackbar
    v-model="show"
    :timeout="timeout"
    :color="color"
  >
    {{ message }}
    <v-btn
      text
      v-show="showCloseButton"
      @click.native="show = false"
    >
      {{ $t('common.close') }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      showCloseButton: true,
      message: '',
      color: '',
      timeout: 0
    }
  },

  created() {
    this.$store.watch(state => state.snack.message, () => {
      const msg = this.$store.state.snack.message;
      if (msg !== '') {
        this.show = true;
        this.message = this.$store.state.snack.message;
        this.color = this.$store.state.snack.color;
        this.timeout = this.$store.state.snack.timeout;
        this.showCloseButton = this.$store.state.snack.showCloseButton;
        this.$store.commit('snack/RESET');
      }
    })
  }
}
</script>